@import "../base/mixins";

.portafolio-content{
    overflow-x: hidden;
    position: relative;
    overflow-y: initial;
    &--banner{
        background-color: #040404;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100vh;
        overflow: hidden;
        position: relative;   
        &--info{
            width: 55%;
            padding-left: 16rem;
            position: relative;
            z-index: 1;
            @include mq(1700){
                padding-left: 6rem;
            }
            @include mq(911){
                width: 100%;
                padding: 2rem;
            }
            span, p{
                font-family: 'inter-regular';
                color: white;
                font-size: 20px;
                @include mq(1025){
                    font-size: 16px;
                    br{
                        display: none;
                    }
                }
            }
            h1{
                font-family: 'inter-bold';
                color: #79EBE8;
                font-size: 70px;
                @include mq(1025){
                   font-size: 45px;
                }
                @include mq(911){
                    font-size: 30px;
                    margin-bottom: 1rem;
                }
            }
            a{
                @include btnPortafolio;
            }
        }
        &--imagen{
            position: absolute;
            right: 0px;
            bottom: -4rem;
            width: 48%;
            padding-right: 7rem;
            z-index: 1;
            @include mq(911){
                display: none;
            }
        }
    }

    &--skills{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        background: #04151E;
        padding: 7rem 0px;
        @include mq(911){
            padding: 2rem 0px;
        }
        &--content{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &--title{
                font-family: 'inter-bold';
                font-size: 70px;
                color: white;
                margin-bottom: 40px;
                @include mq(911){
                    font-size: 30px;
                }
            }
            &--items{
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                @include mq(1700){
                    width: 90%;
                }
                @include mq(911){
                    justify-content: center;
                    gap: 20px;
                }
                .card{
                    position: relative;
                    width: 210px;
                    height: auto;
                    border-radius: 10px;
                    cursor: pointer;
                    text-align: center;
                    transition: 0.5s;
                    $progress-bar-stroke-width: 1.8;
                    &:hover{
                        .number_porcentaje{
                            opacity: 0;
                        }
                        .logoSkill{
                           opacity: 1;
                        }
                    }
                    @include mq(1700){
                        width: 185px;
                    }
                    svg {
                        transform: rotate(-90deg);
                        height: 210px;
                        width: 210px;
                        @include mq(1700){
                            height: 185px;
                            width: 185px;
                        }
                    }

                    .progress-bar__background {
                        fill: none;
                        stroke: white;
                        stroke-width: $progress-bar-stroke-width;
                    }

                    .progress-bar__progress {
                        fill: none;
                        stroke: white;
                        stroke: #5BE5E2;
                        stroke-dasharray: 100 100;
                        stroke-dashoffset: 100;
                        stroke-linecap: round;
                        stroke-width: $progress-bar-stroke-width;
                        transition: stroke-dashoffset 1s ease-in-out;
                    }
                    .number_porcentaje{
                        position: absolute;
                        height: 210px;
                        width: 210px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 2;
                        top: 0px;
                        transition: 0.5s ease-in-out all;
                        @include mq(1700){
                            width: 185px;
                            height: 185px;
                        }
                        p{
                            color: white;
                            font-family: 'inter-bold';
                            font-size: 50px;
                            @include mq(1700){
                                font-size: 40px;
                            }
                        }
                    }
                    .logoSkill{
                        opacity: 0;
                        width: 210px;
                        height: 210px;
                        overflow: hidden;
                        position: absolute;
                        top: 0px;
                        padding: 2rem;
                        transition: 0.5s ease-in-out all;
                        @include mq(1700){
                            width: 185px;
                            height: 185px;
                        }
                    }
                    .nameCard{
                        font-family: 'inter-regular';
                        font-size: 30px;
                        color: white;
                        margin-top: 15px;
                        @include mq(1700){
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    &--sobremi{
        display: flex;
        width: 100%;
        padding: 0px 15rem;
        align-items: center;
        height: 100vh;
        background-image: url('../img/FONDO SOBRE MI.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include mq(911){
            padding: 0px 2rem;
            background-position: 0%;
        }
        &--container{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: initial;
            &--title{
                font-family: 'inter-bold';
                color: white;
                font-size: 70px;
                @include mq(911){
                    font-size: 50px;
                }
            }
            &--text{
                width: 32rem;
                @include mq(911){
                    width: 90%;
                }
                p{
                    font-family: 'inter-regular';
                    font-size: 20px;
                    color: white;
                    margin-bottom: 18px;
                    @include mq(1700){
                        font-size: 18px;
                    }
                }
                b{
                    display: block;
                    font-family: 'inter-bold';
                    font-size: 30px;
                    color: white;
                    margin-bottom: 5px;
                    @include mq(1700){
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &--proyectos{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        min-height: 120vh;
        background: linear-gradient(0deg, #04151e 0%, #040404 40%);
        background-attachment: fixed;
        flex-direction: column;
        &--container{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            &--title{
                font-family: 'inter-bold';
                color: white;
                font-size: 70px;
                margin: 2.5rem 0px 3rem 0px;
                @include mq(911){
                    font-size: 30px; 
                }
            }
            &--items{
                display: flex;
                align-items: flex-start;
                gap: 50px;
                margin-bottom: 3rem;
                width: 100%;
                justify-content: center;
                @include mq(911){
                    width: 90%;
                    flex-direction: column;
                    gap: 7px;
                }
                &--pageImage{
                    background-image: url('../img/CUADRO PROYECTO.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    padding: 2rem;
                    background-position: center center;
                    width: 490px;
                    height: 345px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 15px;
                    @include mq(911){
                        padding: 1rem;
                        width: 100%;
                        height: auto;
                    }
                    h4{
                        font-family: 'inter-bold';
                        font-size: 20px;
                        color: white;
                        @include mq(911){
                            font-size: 16px;
                        }
                    }
                    .imgBanner{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        border-radius: 25px;
                        overflow: hidden;
                        @include mq(911){
                            height: 360px;
                        }
                        @include mq(600){
                            height: 170px;
                        }
                        img{
                            width: 100%;
                            object-fit: contain;
                            transform: translate3d(0px, 0px, 0px);
                            transition: 2s ease-in-out all;
                            cursor: pointer;
                        }
                    }
                }
                &--pageInfo,  &--pageInfo2{
                    width: 360px;
                    height: 345px;
                    @include mq(911){
                        width: 90%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                    }
                    img{
                        width: 40%;
                        margin-bottom: 15px;
                        object-fit: contain;
                        @include mq(911){
                            width: 30%;
                        }
                    }
                    .pageInfo-text{
                        @include mq(911){
                            width: 70%;
                        }
                        p{
                            font-family: 'inter-regular';
                            font-size: 20px;
                            color: white;
                            margin: 10px 0px;
                            @include mq(911){
                                font-size: 16px;
                            }
                        }
                        a{
                            font-family: 'inter-bold';
                            color: white;
                            font-size: 20px;
                            @include mq(911){
                                font-size: 13px;
                            }
                        }
                    }
                }
                &--pageInfo2{
                    text-align: right;
                    @include mq(911){
                        text-align: left;
                    }
                }
            }
            .itemsRevert{
                flex-direction: row-reverse;
                @include mq(911){
                    flex-direction: column;
                }
            }
        }
    }

    &--formulario{
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        background-color: #0C0C0C;
        overflow: hidden;
        @include mq(1025){
            height: auto;
        }
        &--container{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            background-color: rgba(11, 11, 11, 0.8);
            width: 65%;
            min-height: 65vh;
            padding: 3rem;
            position: relative;
            z-index: 2;
            @include mq(1700){
                width: 75%;
                min-height: 80vh;
            }
            @include mq(1025){
                width: 90%;
                flex-direction: column;
                padding: 1rem;
                gap: 30px;
                background-color: rgba(11, 11, 11, 0.5);
            }
            &--info{
                width: 45%;
                display: flex;
                flex-direction: column;
                @include mq(1025){
                    width: 100%;
                }
                &--title{
                    font-family: 'inter-bold';
                    color: white;
                    font-size: 60px;
                    margin-bottom: 2rem;
                    @include mq(1025){
                        font-size: 40px;
                    }
                }
                &--items{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    p{
                        font-family: 'inter-bold';
                        color: white;
                        font-size: 20px;
                        line-height: 30px;
                        span{
                            font-family: 'inter-regular';
                            font-size: 20px;
                        }
                    }
                }
            }
            &--form{
                width: 55%;
                display: flex;
                flex-direction: column;
                @include mq(1025){
                    width: 100%;
                }
                &--title{
                    font-family: 'inter-bold';
                    color: white;
                    font-size: 60px;
                    margin-bottom: 2rem;
                    @include mq(1025){
                        font-size: 40px;
                    }
                }
                &--formulario{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    flex-wrap: wrap; 
                    .container-input{
                        width: 100%;
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        input[type="text"], textarea{
                            background: transparent;
                            border: none;
                            border-bottom: 1px solid white;
                            padding: 0.5rem;
                            font-family: 'inter-regular';
                            font-size: 16px;
                            color: white;
                            &::placeholder{
                                color: white;
                            }
                        }
                        textarea{
                            height: 80px;
                            resize: none;
                        }
                        .error{
                            border-bottom: 1px solid #ec2020 !important;
                        }
                        .error-message{
                            color: #ec2020;
                            font-family: 'inter-bold';
                            position: absolute;
                            right: 1rem;
                            top: 0.5rem;
                            font-size: 12px;
                            z-index: 2;
                            @include mq(600){
                                font-size: 9px;
                            }
                        }
                    }
                    .buttons-forms{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 10px;
                        @include mq(1025){
                            gap: 0px;
                            flex-direction: column-reverse;
                            align-items: flex-start;
                            margin-bottom: 15px;
                        }
                        button{
                            @include btnPortafolio($wd:280px);
                        }
    
                        #send{
                            @include btnPortafolio($wd:180px);
                        }
                    }
                }
            }
        }
    }

    .back-to-top{
        position: fixed;
        bottom: 2rem;
        width: 40px;
        height: 40px;
        right: 3rem;
        background: #1D41DF;
        z-index: 4;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 1px 2px 1px;
        @include mq(911){
            right: 1rem;
        }
        &:hover{
            background: #79EBE8;
        }
        img{
            width: 11px;
            transform: rotate(177deg);
        }
    }

    .modal-regiter{
        position: fixed;
        top: 0px;
        width: 100%;
        height: 100vh;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 100;
        background-color: rgba(11, 11, 11, 0.6);
        &--container{
            background: #FFFFFF;
            width: 500px;
            height: 400px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 4rem;
            gap: 25px;
            @include mq(911){
                width: 390px;
                height: 420px;
            }
            h2{
                font-family: 'inter-bold';
                font-size: 25px;
                color: #1D41DF;
                text-transform: uppercase;
                text-align: center;
            }
            p{
                font-family: 'inter-regular';
                color: #0B0B0B;
                font-size: 18px;
                text-align: center;
            }
            a{
                @include btnPortafolio;
            }
        }
    }
    .openModal{
        display: flex !important;
    }
}